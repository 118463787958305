<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Редактирование организации</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid>
        <template #item-1>
          <app-form-group label-for="name" label="Наименование" required>
            <app-textarea
              v-model="form.value"
              id="name"
              placeholder="Введите наименование"
              :class="{ 'textarea--error': $v.form.value.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.value.$dirty && !$v.form.value.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for=inn label="ИНН" required>
            <app-input
              v-model.trim="form.inn"
              id="inn"
              placeholder="Введите ИНН"
              :class="{ 'input--error': $v.form.inn.$error }"
            />
            <template #error>
              <div v-if="$v.form.inn.$dirty && !$v.form.inn.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for=kpp label="КПП" required>
            <app-input
              v-model.trim="form.kpp"
              id="kpp"
              placeholder="Введите КПП"
              :class="{ 'input--error': $v.form.kpp.$error }"
            />
            <template #error>
              <div v-if="$v.form.kpp.$dirty && !$v.form.kpp.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="address" label="Адрес" required>
            <app-textarea
              v-model="form.address"
              id="address"
              placeholder="Введите адрес"
              :class="{ 'textarea--error': $v.form.address.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.address.$dirty && !$v.form.address.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
        <template #item-2>
          <app-form-group label-for=branch_type label="Branch type" required>
            <app-input
              v-model.trim="form.branch_type"
              id="branch_type"
              placeholder="Введите branch_type"
              :class="{ 'input--error': $v.form.branch_type.$error }"
            />
            <template #error>
              <div v-if="$v.form.branch_type.$dirty && !$v.form.branch_type.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="full_with_opf" label="Full with opf" required>
            <app-textarea
              v-model="form.full_with_opf"
              id="full_with_opf"
              placeholder="Введите full_with_opf"
              :class="{ 'textarea--error': $v.form.full_with_opf.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.full_with_opf.$dirty && !$v.form.full_with_opf.required">Обязательное поле</div>
            </template>
          </app-form-group>
          <app-form-group label-for="short_with_opf" label="Short with opf" required>
            <app-textarea
              v-model="form.short_with_opf"
              id="short_with_opf"
              placeholder="Введите short_with_opf"
              :class="{ 'textarea--error': $v.form.short_with_opf.$error }"
            >
            </app-textarea>
            <template #error>
              <div v-if="$v.form.short_with_opf.$dirty && !$v.form.short_with_opf.required">Обязательное поле</div>
            </template>
          </app-form-group>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { getCommonOrganization, putCommonOrganization, getFullOrg } from '@/http'

export default {
  name: 'EditOrg',
  data() {
    return {
      form: {},
      org_options: [],
    }
  },
  validations: {
    form: {
      value: { required },
      inn: { required },
      kpp: { required },
      address: { required },
      branch_type: { required },
      full_with_opf: { required },
      short_with_opf: { required },
    }
  },
  created() {
    getCommonOrganization(this.$route.params.id)
      .then(response => {
        this.form = response.data
      })
  },
  methods: {
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      putCommonOrganization({ id: this.$route.params.id, form: this.form })
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены'
          })
          this.$router.push({ name: 'org-list' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
  }
}
</script>
